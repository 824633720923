import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import media from "styled-media-query"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const ArticleDate = styled.h5`
  display: inline;
  color: #00b4a0;
  font-family: "Nunito";
  font-weight: 600;
`

const MarkerHeader = styled.h3`
  display: inline;
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  );
`

const ReadingTime = styled.h5`
  display: inline;
  color: #00b4a0;
  font-family: "Nunito";
  font-weight: 600;
`

const PostsWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: row wrap;
`

const PostContainer = styled(Link)`
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: flex-start;
  // margin-right: 2.5%;
  margin-bottom: 5rem;
  text-decoration: none;

  // &:nth-child(4n) {
  //   margin-right: 0;
  // }

  ${media.lessThan("medium")`
        flex: 1 1 100%;
        margin-right: 0;
        margin-bottom: 2.5rem;
    `}
`

const PostImageContainer = styled.div`
  // flex: 1 1 100%;
  height: 30vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
`

const FeaturedImage = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const PostTextWrapper = styled.div`
  display: flex;
  // flex: 1 1 100%;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  margin-top: 0;
  border: 1px solid #EEEEEE;
  border-top: none;

//   ${media.lessThan("medium")`
//         border: 1px solid #EEEEEE;
//         border-top: none;
//         width: 100%;
//         margin-top: 0;
//     `}
// `

const PostTextContainer = styled.div`
padding: 2.5rem;
  // ${media.lessThan("medium")`
  //       padding: 2.5rem;
  //   `}
`

const Title = styled.h2`
  flex: 1 1 100%;
  font-size: 2rem;
  color: #141414;
  font-weight: 400;
  margin-bottom: 2.5rem;
  line-height: 1.3;

  ${media.lessThan("medium")`
        font-size: 2rem;
    `}
`

const Meta = styled.div`
  flex: 1 1 100%;
  font-size: 1.5rem;
  color: #141414;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Content>
        {/* <h1>Blog</h1> */}
        <PostsWrapper>
          {data.allMarkdownRemark.edges
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => {
              const featuredImage =
                node.frontmatter.featuredImage?.childImageSharp.fluid
              return (
                <PostContainer
                  key={node.id}
                  to={node.frontmatter.slug}
                  css={css`
                    text-decoration: none;
                    color: inherit;
                  `}
                >
                  {featuredImage && (
                    <PostImageContainer>
                      <FeaturedImage fluid={featuredImage} />
                    </PostImageContainer>
                  )}
                  <PostTextWrapper>
                    <PostTextContainer>
                      <Title>{node.frontmatter.title}</Title>
                      <Meta>
                        <ArticleDate>{node.frontmatter.date}</ArticleDate>{" "}
                        &nbsp; &nbsp;{" "}
                        <ReadingTime>
                          {node.fields.readingTime.text}
                        </ReadingTime>
                      </Meta>
                    </PostTextContainer>
                  </PostTextWrapper>
                </PostContainer>
              )
            })}
        </PostsWrapper>
        <Helmet>
          <script
            async
            data-uid="4607a58309"
            src="https://fierce-knitter-9980.ck.page/4607a58309/index.js"
          ></script>
        </Helmet>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            slug
            featuredImage {
              childImageSharp {
                fluid(
                  maxWidth: 1500
                  quality: 70
                  cropFocus: ENTROPY
                  duotone: { highlight: "#00b4a0", shadow: "#141414" }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
